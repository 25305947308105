@import url(https://fonts.googleapis.com/css?family=Raleway);
$font-family-sans-serif: 'Raleway', sans-serif;

$gray-base: #1a1a1a;

$brand-primary: #1e456b;
$brand-success: #5cb85c;
$brand-info:    #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger:  #d9534f;

$border-radius-base:  0;
$border-radius-large: 0;
$border-radius-small: 0;

$bootstrap-sass-asset-helper : false;

$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "fonts/") !default;

$screen-lg: 1366px;
$grid-gutter-width : 30px;
$container-large-desktop:      (1306px + $grid-gutter-width);

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

